import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { Badge, Accordion, Card, Button } from "react-bootstrap";

const Experience = ({ siteData }) => {
  const sectionName = siteData?.section_name?.experience;
  const work = siteData?.WorkExperienceAndEducation?.map((work, i) => {
    const mainTechnologies = work.mainTech;

    const mainTech = mainTechnologies.map((technology, i) => {
      return (
        <Badge pill className="main-badge mr-2 mb-2" key={i}>
          {technology}
        </Badge>
      );
    });

    let icon;
    switch (work.type) {
      case "Experience":
        icon = <i className="fas fa-code experience-icon"></i>;
        break;
      case "Education":
        icon = <i className="fas fa-graduation-cap experience-icon"></i>;
        break;
      default:
        break;
    }

    const renderExpAccordianCard = (work) => {
      if (work.projects) {
        const accordianCard = work.projects.map((project, index) => {
          const tech = project.technologies.map((technology, i) => {
            return (
              <Badge pill className="experience-badge mr-2 mb-2" key={i}>
                {technology}
              </Badge>
            );
          });
          return (
            <Card key={index}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={index + 1}
                style={{ cursor: "pointer" }}
              >
                {project.title}
              </Accordion.Toggle>

              <Accordion.Collapse eventKey={index + 1}>
                <Card.Body>
                  <Card.Title>{project.description} </Card.Title>
                  <Card.Text>{tech}</Card.Text>
                  <br />
                  {project.website ? (
                    <Button
                      variant="outline-dark"
                      onClick={() => window.open(project.website)}
                    >
                      Visit {project.title}
                    </Button>
                  ) : null}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          );
        });
        return <Accordion>{accordianCard}</Accordion>;
      }
    };

    return (
      <VerticalTimelineElement
        iconOnClick={() => {
          if (work.website) {
            window.open(work.website);
          }
        }}
        visible={false}
        date={work.years}
        iconStyle={{
          background: "#a38a66",
          color: "#fff",
          textAlign: "center",
          cursor: work.website && "pointer",
        }}
        icon={icon}
        key={i}
      >
        <div>
          <div style={{ textAlign: "left", marginBottom: "4px" }}>
            {mainTech}
          </div>
          <h3 style={{ textAlign: "left" }}>{work.title}</h3>
          <h4 style={{ textAlign: "left" }}>{work.company}</h4>
          {renderExpAccordianCard(work)}
        </div>
      </VerticalTimelineElement>
    );
  });

  return (
    <section id="resume" className="pb-5">
      <div className="col-md-12 mx-auto">
        <div className="col-md-12">
          <h1 className="section-title" style={{ color: "black" }}>
            <span className="text-black" style={{ textAlign: "center" }}>
              {sectionName}
            </span>
          </h1>
        </div>
      </div>
      <div className="col-md-8 mx-auto">
        <VerticalTimeline>
          {work}
          <VerticalTimelineElement
            iconStyle={{
              background: "#a38a66",
              color: "#fff",
              textAlign: "center",
            }}
            icon={<i className="far fa-star mx-auto experience-icon"></i>}
          />
        </VerticalTimeline>
      </div>
    </section>
  );
};

export default Experience;
