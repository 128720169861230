import React from "react";

const Footer = ({ siteData }) => {
  const networks = siteData?.social?.map((network) => {
    return (
      <span key={network.name} className="m-4">
        <a href={network.url} target="_blank" rel="noopener noreferrer">
          <i className={network.class}></i>
        </a>
      </span>
    );
  });

  return (
    <footer>
      <div className="col-md-12">
        <div className="social-links">{networks}</div>

        <div className="copyright py-4 text-center">
          <div className="container">
            <small>Copyright &copy; {siteData?.basic_info?.name}</small>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
