import React from "react";

const Skills = ({ siteData }) => {
  const renderSkillSection = () => {
    return siteData?.section_name.skills?.map((sectionName, i) => {
      return (
        <div className="col-md-12" key={i}>
          <div className="col-md-12">
            <h1 className="section-title">
              <span className="text-white">{sectionName}</span>
            </h1>
          </div>
          <div className="col-md-12 text-center">
            <ul className="list-inline mx-auto skill-icon">
              {renderSkillIcons(sectionName)}
            </ul>
          </div>
        </div>
      );
    });
  };

  const renderSkillIcons = (sectionName) => {
    return siteData?.skills[sectionName]?.map((icon, i) => {
      return (
        <li className="list-inline-item mx-3" key={i}>
          <span>
            <div className="text-center skills-tile">
              <i className={icon.class} style={{ fontSize: "220%" }}>
                <p
                  className="text-center"
                  style={{ fontSize: "30%", marginTop: "4px" }}
                >
                  {icon.name}
                </p>
              </i>
            </div>
          </span>
        </li>
      );
    });
  };

  return <section id="skills">{renderSkillSection()}</section>;
};

export default Skills;
