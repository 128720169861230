import React, { useEffect, useState } from "react";
import ProjectDetailsModal from "./ProjectDetailsModal";

const Projects = ({ siteData }) => {
  const sectionName = siteData?.section_name.projects;

  const [deps, setDeps] = useState({});
  const [detailsModalShow, setDetailsModalShow] = useState(false);
  const [projects, setProjects] = useState([]);

  const detailsModalShowFunc = (data) => {
    setDeps(data);
    setDetailsModalShow(true);
  };

  const detailsModalClose = () => setDetailsModalShow(false);

  useEffect(() => {
    if (siteData?.projects && !projects.length) {
      setProjects(siteData?.projects);
    }
  }, [siteData?.projects, projects]);

  const renderProjects = projects.map((project) => {
    return (
      <div
        className="col-sm-12 col-md-6 col-lg-4"
        key={project.title}
        style={{ cursor: "pointer" }}
      >
        <span className="portfolio-item d-block">
          <div className="foto" onClick={() => detailsModalShowFunc(project)}>
            <div>
              <img
                src={project.images[0]}
                alt="projectImages"
                height="230"
                style={{
                  marginBottom: 0,
                  paddingBottom: 0,
                  position: "relative",
                }}
              />
              <span className="project-date">{project.startDate}</span>
              <br />
              <p className="project-title-settings mt-3">{project.title}</p>
            </div>
          </div>
        </span>
      </div>
    );
  });

  return (
    <section id="portfolio">
      <div className="col-md-12">
        <h1 className="section-title" style={{ color: "black" }}>
          <span>{sectionName}</span>
        </h1>
        <div className="col-md-12 mx-auto">
          <div className="row mx-auto">{renderProjects}</div>
        </div>
        <ProjectDetailsModal
          show={detailsModalShow}
          onHide={detailsModalClose}
          data={deps}
        />
      </div>
    </section>
  );
};

export default Projects;
